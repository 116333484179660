import React from "react";

import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Chart from "src/components/Chart";
import { formatPercent, formatWholeDollars } from "src/utils/formatter";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  table: {
    margin: "20px auto",
    maxWidth: 800,
  },
  cell: {
    border: 0,
  },
  columnHeader: {
    border: 0,
    fontWeight: 600,
    wordBreak: "break-word",
  },
  rowHeader: {
    border: 0,
    fontWeight: 600,
    wordBreak: "break-word",
  },
  summaryRow: {
    fontSize: 14,
    marginTop: 12,
  },
});

const Results = ({ back, results }: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="Results">
        <Chart
          dollars
          title=""
          elevation={0}
          data={results.data}
          height="322px"
          keys={["high", "base", "low"]}
          xInterval={12}
          xKey="month"
          xYearOnly
          xMargin={60}
          yMargin={15}
          yScale="log"
          yLabelMargin={65}
          keyLabels={{
            high: "High Case",
            base: "Base Case",
            low: "Low Case",
          }}
          width="100%"
        />
        <TableContainer className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.columnHeader} />
                <TableCell className={styles.columnHeader}>5 Years</TableCell>
                <TableCell className={styles.columnHeader}>10 Years</TableCell>
                <TableCell className={styles.columnHeader}>20 Years</TableCell>
                <TableCell className={styles.columnHeader}>30 Years</TableCell>
                <TableCell className={styles.columnHeader}>40 Years</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={styles.rowHeader}>High Case</TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[4].high)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[9].high)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[19].high)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[29].high)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[39].high)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.rowHeader}>Base Case</TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[4].base)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[9].base)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[19].base)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[29].base)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[39].base)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.rowHeader}>Low Case</TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[4].low)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[9].low)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[19].low)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[29].low)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.data[39].low)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card
        className="mt-8"
        title="Expected Returns of Portfolio with Selected Risk Profile"
      >
        <Typography className={styles.summaryRow}>
          Avg. Annual Expected Return (High Case):{" "}
          {formatPercent(results.annual_high_return * 100)}
        </Typography>
        <Typography className={styles.summaryRow}>
          Avg. Annual Expected Return (Base Case):{" "}
          {formatPercent(results.annual_base_return * 100)}
        </Typography>
        <Typography className={styles.summaryRow}>
          Avg. Annual Expected Return (Low Case):{" "}
          {formatPercent(results.annual_low_return * 100)}
        </Typography>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={back}>
          Back
        </Button>
      </Box>
    </>
  );
};

export default Results;
