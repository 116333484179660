import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./App.css";

import { theme } from "./theme";

import configureStore from "./store";
import BaseRouter from "./router";

import { AppConfig } from "src/config";
import { initializeTapfiliate } from "src/tapfiliate/";
import SidebarWrapper from "src/components/SidebarWrapper";
import UpgradeDialog from "src/components/Upgrade";
import RootConfirmationDialog from "src/components/RootConfirmationDialog";

const stripePromise = loadStripe(AppConfig.stripe.public_key);
console.log("App version dev-2023-10-06");

const App = () => {
  initializeTapfiliate();
  const [rootStore, setRootStore] = useState<any | undefined>(undefined);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore();
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  if (!rootStore) {
    return null;
  }
  return (
    <Provider store={rootStore.store}>
      <PersistGate loading={null} persistor={rootStore.persistor}>
        <ThemeProvider theme={theme}>
          <Elements stripe={stripePromise}>
            <CssBaseline />
            <SidebarWrapper>
              <BaseRouter />
            </SidebarWrapper>
            <UpgradeDialog />
            <RootConfirmationDialog />
          </Elements>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
