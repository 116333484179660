import React, { useMemo, useState } from "react";
import { forOwn } from "lodash";
import clsx from "clsx";

import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";

import StatusIndicator from "src/components/StatusIndicator";

import { ALL_CASHFLOW_TYPES, MappedCashflow } from "src/interfaces";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { DollarTextField, formatWholeDollars } from "src/utils";

interface BudgetCardProps {
  title: string;
  addLabel?: string;
  actual?: { [typeId: string]: number };
  budget: MappedCashflow[];
  editing?: boolean;
  onAdd: () => void;
  onSave: (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>,
    newValues: { [type: string]: number }
  ) => void;
  annual?: boolean;
  showTax?: boolean;
  addOwner?: boolean;
  taxBudget?: number;
  openTransactionsDialog?: (key: string) => void;
}

const BudgetCard = ({
  title,
  addLabel,
  budget,
  actual,
  onAdd,
  editing,
  onSave,
  annual,
  showTax,
  taxBudget,
  addOwner,
  openTransactionsDialog,
}: BudgetCardProps) => {
  const classes = useStyles();
  const [changes, setChanges] = useState<{ [stringId: string]: number }>({});
  const [deletedIds, setDeletedIds] = useState<Set<number>>(new Set());
  const [otherExpanded, setOtherExpanded] = useState(false);
  const [addValues, setAddValues] = useState<{ [type: string]: number }>({});

  const toggleOtherExpanded = () => setOtherExpanded((current) => !current);

  const listKeys = useMemo(() => budget.map(Math.random), [budget.length]);
  const subscribed = !!actual;
  const totalBudget = useMemo(
    () =>
      budget.reduce((result: number, item: MappedCashflow) => {
        if (deletedIds.has(item.id)) {
          return result;
        }
        const value = item.annual || 0;
        return value + result;
      }, 12 * (taxBudget || 0)),
    [budget, deletedIds, taxBudget]
  );

  const handleChange = (e: React.ChangeEvent<any>, item: MappedCashflow) => {
    const value = Math.abs(e.target.value);
    const newChanges = { ...changes, [item.id + ""]: value };
    setChanges(newChanges);
  };

  const handleNewChange = (e: React.ChangeEvent<any>, newType: string) => {
    const value = Math.abs(e.target.value);
    const newAddValues = { ...addValues, [newType]: value };
    setAddValues(newAddValues);
  };

  const handleDelete = (id: number) => {
    const newDeletedIds = new Set(deletedIds);
    newDeletedIds.add(id);
    setDeletedIds(newDeletedIds);
  };

  const handleEditClick = () => {
    if (editing) {
      onSave(changes, deletedIds, addValues);
    } else {
      onSave({}, new Set(), {});
    }
    setChanges({});
    setDeletedIds(new Set());
    setAddValues({});
  };

  const seenTypes: Set<string> = new Set();

  const {
    displayBudget,
    displayActual,
    otherDetail,
    foundCredits,
  } = useMemo(() => {
    const displayBudget = budget.slice();
    let foundCredits: number | undefined;
    const displayActual = actual ? { ...actual } : undefined;
    const otherDetail: Array<{ type: string; value: number }> = [];

    try {
      if (displayActual) {
        forOwn(actual, (value: number, ownerType: string) => {
          const [type, whose] = ownerType.split("__");
          if (type === "taxes") {
            return true;
          }
          if (type === "credits") {
            foundCredits = (foundCredits || 0) + value
            if (!displayActual.unbudgetedTotal) {
              displayActual.unbudgetedTotal = 0;
            }
            displayActual.unbudgetedTotal += value;
            return true;
          }
          const foundType = budget.find((budgetItem) => {
            if (budgetItem.type !== type) {
              return false;
            }
            if (!whose) {
              return true;
            }
            if (budgetItem.whose === "spouse") {
              if (whose === "spouse") {
                return true;
              }
              return false;
            }
            if (whose === "spouse") {
              return false;
            }
            return true;
          });
          if (!foundType) {
            if (!displayActual.unbudgetedTotal) {
              displayActual.unbudgetedTotal = 0;
            }
            displayActual.unbudgetedTotal += value;
            otherDetail.push({ type: ownerType, value });
          }
        });
      }
    } catch (e) {
      console.error({ e });
    }
    return {
      displayBudget,
      displayActual,
      foundCredits,
      otherDetail,
    };
  }, [budget, actual]);

  let actualTotal: number = actual && showTax ? actual.taxes || 0 : 0;
  if (displayActual && displayActual.unbudgetedTotal) {
    actualTotal += displayActual.unbudgetedTotal;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <p className={classes.title}>{title}</p>
        <div className={classes.elements}>
          <StatusIndicator title="Actual" />
          <StatusIndicator title="Budgeted" fill={colors.brandingBlue2} />
        </div>
      </div>
      <div className={classes.content}>
        {showTax && (
          <div key="tax" className={clsx(classes.header)}>
            <p>Taxes</p>
            <div className={classes.elements}>
              <Box className={classes.buttonContainer}>
                {!!openTransactionsDialog && !!actual && !!actual.taxes && (
                  <IconButton
                    className={classes.transactionsButton}
                    color="primary"
                    onClick={() => openTransactionsDialog("taxes")}
                  >
                    <Icon iconName="fa-pencil" />
                  </IconButton>
                )}
                {subscribed ? (
                  <p className={classes.taxesLabel}>
                    {actual?.taxes ? formatWholeDollars(actual.taxes) : ""}
                  </p>
                ) : (
                  <Tooltip title="To track your actual income and expenses, upgrade to our premium membership.">
                    <span>
                      <Icon iconName="fb-information" />
                    </span>
                  </Tooltip>
                )}
              </Box>
              <div>
                <DollarTextField
                  variant="outlined"
                  required
                  name="tax"
                  onChange={() => null}
                  value={Math.floor(
                    annual ? (taxBudget || 0) * 12 : taxBudget || 0
                  )}
                  InputProps={{ readOnly: true, className: classes.taxesLabel }}
                  inputProps={{ style: { fontSize: 14 } }}
                  decimalScale={editing ? undefined : 0}
                />
              </div>
            </div>
          </div>
        )}
        <>
          {displayBudget
            .filter((item) => !deletedIds.has(item.id))
            .map((item, index) => {
              const whose: string =
                addOwner && item.whose === "spouse" ? "spouse" : "applicant";
              const ownerType: string = addOwner
                ? `${item.type as string}__${whose}`
                : (item.type as string);
              let currentValue: any = annual ? item.annual : item.monthly;
              if (item.id + "" in changes) {
                currentValue = changes[item.id + ""];
              }
              let actualValue = displayActual && displayActual[ownerType] !== undefined
                ? formatWholeDollars(displayActual[ownerType] || 0) : "";
              if (seenTypes.has(ownerType)) {
                actualValue = "N/A";
              } else if (displayActual && displayActual[ownerType]) {
                actualTotal += displayActual[ownerType] || 0;
                seenTypes.add(ownerType);
              }
              return (
                <div key={listKeys[index]} className={clsx(classes.header)}>
                  <p>
                    {item.typeLabel}
                    {addOwner
                      ? item.whose === "spouse"
                        ? " (Spouse)"
                        : ""
                      : ""}
                  </p>
                  <div className={classes.elements}>
                    <Box className={classes.buttonContainer}>
                      {!index && !showTax && !subscribed && (
                        <Tooltip title="To track your actual income and expenses, upgrade to our premium membership.">
                          <span>
                            <Icon iconName="fb-information" />
                          </span>
                        </Tooltip>
                      )}
                      {!!actualValue && !!openTransactionsDialog && (
                        <IconButton
                          className={classes.transactionsButton}
                          color="primary"
                          onClick={() => {
                            const splitType = (item.type as string).split("__");
                            openTransactionsDialog(splitType[0]);
                          }}
                        >
                          <Icon iconName="fa-pencil" />
                        </IconButton>
                      )}
                      <p>{actualValue}</p>
                    </Box>
                    <div className={`${editing && classes.editable}`}>
                      <DollarTextField
                        variant="outlined"
                        required
                        name={item.type}
                        onChange={(e: React.ChangeEvent<any>) =>
                          handleChange(e, item)
                        }
                        value={currentValue}
                        InputProps={{ readOnly: !editing }}
                        inputProps={{ style: { fontSize: 14 } }}
                        decimalScale={editing ? undefined : 0}
                      />
                      {editing && (
                        <IconButton
                          className={classes.deleteButton}
                          color="primary"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Icon iconName="fb-trash-can" />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </>
        {(displayActual && (otherDetail.length > 0 || foundCredits !== undefined)) && (
          <>
            <div className={clsx(classes.header)}>
              <p>
                <span className="font-semibold">Unbudgeted</span>
                <IconButton onClick={toggleOtherExpanded}>
                  <Icon
                    iconName={
                      otherExpanded ? "fb-chevron-down" : "fb-chevron-right"
                    }
                  />
                </IconButton>
              </p>
              <div className={classes.elements}>
                <p>{formatWholeDollars(displayActual.unbudgetedTotal || 0)}</p>
                <div />
              </div>
            </div>
            {otherExpanded &&
              otherDetail.map((item) => {
                const ownerType = item.type;
                const splitType = ownerType.split("__");
                let typeLabel = ALL_CASHFLOW_TYPES[splitType[0]] || "Unknown";
                if (addOwner && splitType?.[1] === "spouse") {
                  typeLabel += " (Spouse)";
                }
                return (
                  <div key={ownerType} className={clsx(classes.header)}>
                    <p>{typeLabel}</p>
                    <div className={classes.elements}>
                      <Box className={classes.buttonContainer}>
                        {(!!openTransactionsDialog && item.value !== undefined) && (
                          <IconButton
                            className={classes.transactionsButton}
                            color="primary"
                            onClick={() => {
                              const splitType = (item.type as string).split(
                                "__"
                              );
                              openTransactionsDialog(splitType[0]);
                            }}
                          >
                            <Icon iconName="fa-pencil" />
                          </IconButton>
                        )}
                        <p>{formatWholeDollars(item.value)}</p>
                      </Box>
                      <div className={`${editing && classes.editable}`}>
                        <DollarTextField
                          variant="outlined"
                          required
                          name={item.type}
                          onChange={(e: React.ChangeEvent<any>) =>
                            handleNewChange(e, item.type)
                          }
                          value={addValues[item.type] || ""}
                          InputProps={{ readOnly: !editing }}
                          inputProps={{ style: { fontSize: 14 } }}
                          decimalScale={editing ? undefined : 0}
                        />
                        {editing && (
                          <IconButton className={classes.deleteButton} />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            {otherExpanded && foundCredits !== undefined && (
              <div key="credit" className={clsx(classes.header)}>
                <p>Credit/Refund</p>
                <div className={classes.elements}>
                  <Box className={classes.buttonContainer}>
                    {!!openTransactionsDialog && (
                      <IconButton
                        className={classes.transactionsButton}
                        color="primary"
                        onClick={() => openTransactionsDialog("credits")}
                      >
                        <Icon iconName="fa-pencil" />
                      </IconButton>
                    )}
                    <p>{formatWholeDollars(foundCredits)}</p>
                  </Box>
                  <div className="py-2">N/A</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.footer}>
        <div className={clsx(classes.header)}>
          <p>Total {title}</p>
          <div className={classes.elements}>
            <p>{subscribed && formatWholeDollars(actualTotal)}</p>
            <p>
              {formatWholeDollars(
                annual ? totalBudget : Math.floor(totalBudget / 12)
              )}
            </p>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            variant={editing ? "contained" : "outlined"}
            fbColor="primary"
            className={`w-5/12 mt-5 mr-4 ${classes.button}`}
            onClick={handleEditClick}
            endIcon={<Icon iconName="fa-pencil" />}
          >
            {editing ? "Done" : "Edit"}
          </Button>
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onAdd}
            className={`w-full mt-5 ${classes.button}`}
            endIcon={<Icon iconName="fb-add-alt" />}
          >
            {addLabel || `Add ${title}`}
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    borderRadius: 28,
    background: colors.white,
    boxShadow: shadows[4],
    padding: 24,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 12,
    "&> p": {
      width: "45%",
    },
    "&> div": {
      width: "55%",
    },
  },
  elements: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&> div, &>  p": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center !important",
      width: "55%",
    },
  },
  content: {
    borderBottom: `1px solid ${colors.blueGray7}`,
    borderTop: `1px solid ${colors.blueGray7}`,
    padding: "15px 0px 0px 0px",
    "& .>div": {
      width: "inherit",
      margin: 0,
    },
    "& input": {
      padding: "8px 20px",
      textAlign: "center",
    },
    "& fieldset": {
      border: "none",
    },
    "& p": {
      margin: 0,
    },
  },
  adornment: {
    paddingBottom: 1,
  },
  title: {
    color: colors.brandingBlue1,
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
  },
  editable: {
    "& fieldset": {
      border: `1px solid ${colors.blueGray3} !important`,
    },
  },
  deleteButton: {
    marginRight: -20,
    paddingBottom: 0,
    paddingTop: 0,
  },
  buttonContainer: {
    position: "relative",
    justifyContent: "space-between !important",
    display: "flex",
    overflowY: "visible",
  },
  transactionsButton: {
    display: "block",
    marginLeft: -15,
    marginRight: 4,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0,
    width: 0,
  },
  footer: {
    "& p": {
      fontWeight: 600,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  taxesLabel: {
    opacity: 0.6,
  },
});

export default BudgetCard;
