import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { Modal } from "src/components/Dialogs";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { getIdrData, setIdrDetails } from "src/store/dashboard/actions";
import { getAnyFedLoansLinked } from "src/store/account/selector";
import { getIdrData as selectIdrData } from "src/store/dashboard/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { AdapterLink, formatWholeDollars, PercentTextField } from "src/utils";
import { AppState } from "src/store";

const useStyles = makeStyles({
  detailRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 6,
    marginTop: 6,
  },
  detail: {
    fontSize: 14,
  },
  button: {
    display: "block",
    margin: "18px auto 0",
  },
  notLinked: {},
});

const IDRSummary = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const idrData = useSelector(selectIdrData);

  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    ...idrData.myIdrAssumptions,
  });
  const [showSpouseData, setShowSpouseData] = useState(
    !!spouseIdr && !applicantIdr
  );
  const [selectedAccountIds, setSelectedAccountIds] = useState<Set<number>>(
    new Set([])
  );

  const data = showSpouseData
    ? idrData.spouseIdrOverview
    : idrData.myIdrOverview;
  const accounts = showSpouseData
    ? idrData.spouseIdrAccounts
    : idrData.myIdrAccounts;
  const selectedAccounts = accounts.filter((account) => account.selected);
  const canSelectSpouse = applicantIdr && spouseIdr;
  const remainingTerm = data?.result?.remainingterm || 0;
  const remainingTermText = (remainingTerm / 12).toFixed(1);
  const linkedAccounts = useSelector((state: AppState) => getAnyFedLoansLinked(state, showSpouseData));

  useEffect(() => {
    if (subscribed && (applicantIdr || spouseIdr)) {
      dispatch(getIdrData({ applicant: applicantIdr, spouse: spouseIdr }));
      if (spouseIdr && !applicantIdr) {
        setShowSpouseData(true);
      }
    }
  }, [subscribed, applicantIdr, spouseIdr]);

  useEffect(() => {
    if (showSpouseData) {
      setFormValues({ ...idrData.spouseIdrAssumptions });
    } else {
      setFormValues({ ...idrData.myIdrAssumptions });
    }
  }, [idrData.myIdrAssumptions, idrData.spouseIdrAssumptions, showSpouseData]);

  useEffect(() => {
    const newSelectedAccountIds: Set<number> = new Set([]);
    accounts.forEach((account) => {
      if (account.selected) {
        newSelectedAccountIds.add(account.id);
      }
    });
    setSelectedAccountIds(newSelectedAccountIds);
  }, [accounts]);

  const toggleSelectedAccount = (accountId: number) => {
    const newSelectedAccountIds: Set<number> = new Set(selectedAccountIds);
    if (selectedAccountIds.has(accountId)) {
      newSelectedAccountIds.delete(accountId);
    } else {
      newSelectedAccountIds.add(accountId);
    }
    setSelectedAccountIds(newSelectedAccountIds);
  };
  const toggleShowSpouseData = () => setShowSpouseData(!showSpouseData);

  const openEditDialog = () => setEditDialogOpen(true);
  const closeEditDialog = () => setEditDialogOpen(false);
  const saveUpdates = () => {
    dispatch(
      setIdrDetails({
        who: showSpouseData ? "spouse" : "applicant",
        accountIds: Array.from(selectedAccountIds),
        assumptions: formValues,
      })
    );
    closeEditDialog();
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    return setFormValues({ ...formValues, [field]: value });
  };

  if (
    !subscribed ||
    (!applicantIdr && !spouseIdr) ||
    (data?.result?.remainingterm || 0) <= 0
  ) {
    return null;
  }

  const getSaved = (target: any[][]) => {
    const nowMonthYear = new Date().toISOString().slice(0, 7);
    const targetMonthYear = target.find(
      (item: any[]) => item?.[0] === nowMonthYear
    );
    return targetMonthYear?.[1] || 0;
  };

  const loansNotLinked = linkedAccounts.length == 0;

  return (
    <Card
      title={`IDR Details${spouseIdr && !canSelectSpouse ? " (Spouse)" : ""}`}
      headComponent={
        canSelectSpouse ? (
          <Select
            variant="outlined"
            onChange={toggleShowSpouseData}
            value={showSpouseData ? "spouse" : "mine"}
          >
            <MenuItem value="mine">Mine</MenuItem>
            <MenuItem value="spouse">Spouse</MenuItem>
          </Select>
        ) : undefined
      }
    >
      {loansNotLinked && (
        <Box className={styles.notLinked}>
          <AdapterLink to="/accounts">Please link your accounts</AdapterLink>
        </Box>
      )}
      {!loansNotLinked && (
        <>
          {remainingTerm > 0 && (
            <>
              <Box className={styles.detailRow}>
                <Typography className={styles.detail}>
                  Expected Tax Liability in {remainingTermText} Years
                </Typography>
                <Typography className={styles.detail}>
                  {formatWholeDollars(data?.result?.taxliability || 0)}
                </Typography>
              </Box>
              <Box className={styles.detailRow}>
                <Typography className={styles.detail}>
                  Expected Amount Forgiven in {remainingTermText} Years
                </Typography>
                <Typography className={styles.detail}>
                  {formatWholeDollars(data?.result?.forgiven || 0)}
                </Typography>
              </Box>
            </>
          )}
          <Box className={styles.detailRow}>
            <Typography className={styles.detail}>
              Expected Saved Amount to Date
            </Typography>
            <Typography className={styles.detail}>
              {formatWholeDollars(getSaved(data?.target || []))}
            </Typography>
          </Box>
          <Box className={styles.detailRow}>
            <Typography className={styles.detail}>
              Actual Saved Amount to Date
            </Typography>
            <Typography className={styles.detail}>
              {formatWholeDollars(getSaved(data?.actual || []))}
            </Typography>
          </Box>
          <Box className={styles.detailRow}>
            <Typography className={styles.detail}>
              Recommended Minimum Monthly Savings
            </Typography>
            <Typography className={styles.detail}>
              {formatWholeDollars(data?.result?.monthlyamount || 0)}
            </Typography>
          </Box>
          <Typography className={styles.detail + " mt-5"}>
            Accounts used to save for tax:
            {!selectedAccounts.length ? " None" : ""}
          </Typography>
          {!!selectedAccounts.length && (
            <ul>
              {selectedAccounts.map((account) => (
                <li>{account.name}</li>
              ))}
            </ul>
          )}
          <Button
            className={styles.button}
            fbColor="primary"
            onClick={openEditDialog}
          >
            Change Accounts &amp; Assumptions
          </Button>
        </>
      )}
      <Modal
        className="overflow-y-hidden"
        title=""
        size="lg"
        isOpen={editDialogOpen}
        onClose={closeEditDialog}
      >
        <Box className="flex justify-between">
          <Card
            className="w-1/2 mr-5"
            title="Which Account(s) Are You Using for Tax Savings"
          >
            <Box className="pt-5">
              {accounts.map((account) => (
                <FormControlLabel
                  className="block"
                  label={account.name || account.firm}
                  control={
                    <Checkbox
                      checked={selectedAccountIds.has(account.id)}
                      onChange={() => toggleSelectedAccount(account.id)}
                    />
                  }
                />
              ))}
            </Box>
          </Card>
          <Card className="w-1/2" title="Update Assumptions">
            <Box className="pt-5">
              <Box className={styles.detailRow}>
                <Typography variant="body1">
                  Long-term expected rate of increase in income
                </Typography>
                <PercentTextField
                  className="w-16"
                  variant="outlined"
                  name="earnings_growth"
                  onChange={handleChange}
                  value={formValues.earnings_growth || 0}
                />
              </Box>
              <Box className={styles.detailRow}>
                <Typography variant="body1">
                  Long-term estimated tax rate
                </Typography>
                <PercentTextField
                  className="w-16"
                  variant="outlined"
                  name="tax_rate"
                  onChange={handleChange}
                  value={formValues.tax_rate || 0}
                />
              </Box>
              <Box className={styles.detailRow}>
                <Typography variant="body1">
                  Long-term expected annual rate of return on savings
                </Typography>
                <PercentTextField
                  className="w-16"
                  variant="outlined"
                  name="savings_rate"
                  onChange={handleChange}
                  value={formValues.savings_rate || 0}
                />
              </Box>
            </Box>
          </Card>
        </Box>
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={saveUpdates}
        >
          Save
        </Button>
      </Modal>
    </Card>
  );
};

export default IDRSummary;
