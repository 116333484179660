import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Box, CircularProgress, makeStyles } from "@material-ui/core";

import { getProjectedInvestmentReturnApi } from "src/apiService";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { Account, isInvestmentType } from "src/interfaces";
import { getAccounts } from "src/store/account/selector";
import Introduction from "./Introduction";
import Input from "./Input";
import SelectRisk from "./SelectRisk";
import Results from "./Results";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  loading: {
    margin: "120px auto",
    width: 0,
  },
});

enum STEPS {
  INTRODUCTION,
  INPUT,
  SELECT_RISK,
  RESULTS,
}

const INVESTMENT_PROJECTIONS_DISCLAIMER =
  "Disclaimer: FitBUX's recommended risk level and associated portfolio allocations are based on your FitBUX Score and the completeness of your FitBUX Profile. Therefore, FitBUX is not liable for incomplete or non-updated profiles. FitBUX is also not liable for you implementing the recommended risk level or associated portfolio allocations to your own portfolios. FitBUX's recommended allocations are based on our proprietary models developed by FitBUX RIA LLC.";

const InvestmentProjections = () => {
  const styles = useStyles();
  const accounts: Account[] = useSelector(getAccounts);
  const [investments, setInvestments] = useState(
    accounts
      .filter((account) => isInvestmentType(account.variable || ""))
      .map((account) => ({
        type: account.variable,
        balance: account.balance,
        contribution: 0,
        who: account.who,
      }))
  );
  const [allocation, setAllocation] = useState(50);
  const [step, setStep] = useState(STEPS.INTRODUCTION);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>({ data: [] });

  const getResults = () => {
    setLoading(true);
    const portfolio = `${100 - allocation}_${allocation}`;
    getProjectedInvestmentReturnApi({
      portfolio,
      accounts: investments.map((item) => ({
        type: item.type || "",
        balance: item.balance || 0,
        monthly_contribution: item.contribution || 0,
      })),
    }).then((response: any) => {
      setLoading(false);
      const formattedResults = response.base.map(
        (value: number, index: number) => ({
          base: value,
          high: response.high[index],
          low: response.low[index],
          month: index * 12,
        })
      );
      setResults({
        annual_base_return: response.annual_base_return,
        annual_high_return: response.annual_high_return,
        annual_low_return: response.annual_low_return,
        data: formattedResults,
      });
      setStep(STEPS.RESULTS);
    });
  };

  const renderStep = () => {
    if (loading) {
      return (
        <Box className={styles.loading}>
          <CircularProgress size={80} />
        </Box>
      );
    }
    switch (step) {
      case STEPS.INTRODUCTION:
        return <Introduction next={() => setStep(STEPS.INPUT)} />;
      case STEPS.INPUT:
        return (
          <Input
            back={() => setStep(STEPS.INTRODUCTION)}
            investments={investments}
            setInvestments={setInvestments}
            next={() => setStep(STEPS.SELECT_RISK)}
          />
        );
      case STEPS.SELECT_RISK:
        return (
          <SelectRisk
            back={() => setStep(STEPS.INPUT)}
            allocation={allocation}
            setAllocation={setAllocation}
            next={getResults}
          />
        );
      case STEPS.RESULTS:
        return (
          <Results results={results} back={() => setStep(STEPS.SELECT_RISK)} />
        );
    }
  };

  return (
    <CalculatorLayout
      title="Investment Projections"
      backTo="/investments"
      disclaimer={INVESTMENT_PROJECTIONS_DISCLAIMER}
    >
      <Box className={styles.container}>{renderStep()}</Box>
    </CalculatorLayout>
  );
};

export default InvestmentProjections;
